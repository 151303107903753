.App {
  text-align: center;
}

.App-header {
  background-color: #FFF;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border-bottom: 1px solid #ccc;

}

.App-link {
  color: #61dafb;
}
.title{
  position: relative;
  padding-top:5px;
  padding-bottom:5px;
  //min-height: 10 vh;
  text-align: center;
  font-size: 2.5em;
  font-weight: bold;
  color: #000000;
  font-family: Arial, sans-serif;
  border-bottom: 1px solid #ccc;
}

a {
  display: block;
  text-decoration: none;
  color: #333;
}

.searchResultItem:hover {
  border: 2px solid black;
}