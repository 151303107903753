.content{
  min-width:500px;
  width: 100%;
}

.protein-details {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow:visible; /* left column takes 1/5 of the width, right column takes 4/5 */
}

.App-header {
  display: flex;
  height:100px;
  width :100%;
  border-bottom: 1px solid #ccc;
}

.tabs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 200px;
}
  
.active {
  display: block;
  width: 100%;
  height: 50px;
  margin-bottom: 0px;
  font-size: 30px;
  font-family: Arial, sans-serif;
  background-color: #ECECEC;
  color: #000000;
  border: 1px solid #ccc;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  text-align: right;
}
  
.inactive {
  display: block;
  width: 100%;
  height: 50px;
  margin-bottom: 0px;
  font-size: 30px;
  font-family: Arial, sans-serif;
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid #ccc;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  text-align: right;
}
  
.active:hover {
  background-color: #C8C8C8;
}

.inactive:hover {
  background-color: #C8C8C8;
}
.expression {
  /* other CSS rules */
  text-align: left; /* add this line to align the container to the left */
}

.dropdown-container.show {
  position: relative;
  justify-content:flex-end;
  width:100%;
  overflow: hidden;
  animation: slide-down 0.5s ease;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
  
@keyframes slide-up {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}
  
@keyframes shrink {
  0% {
    height: 100%;
  }
  100% {
    height: 0; /* set the height to the height of one button */
  }
}

.dropdown-container.hide {
  height:0 !important;
  overflow: hidden;
  transition: 0.3s ease-out;
  animation: slide-down 0.5s ease;
}

.dropdown {
  position: relative;
  left: 0%;
  width: 100%;
  background-color: #fff;
  border: 0px solid #ccc;
  display: flex;
  justify-content:flex-end;
  flex-direction: column;
  text-align: right;
  
}

.dropdown button {
  background-color: #fff;
  text-align: right;
  border: 1px solid #ccc;
  font-family: 'Arial';
}

.dropdown button:hover {
  background-color: #C8C8C8;
}
