.iframe{
    width:1450px;
    height:700px;
}

  .iframe-button:hover,
  .iframe-select:hover {
    background-color: #ccc;
  }
  .iframe-button,
  .iframe-select{
    background-color: #fff;
  }