.amino-acid-sequence {
    display: flex;
    overflow-x: scroll;
    cursor: grab;
    width: 100%;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    box-sizing: border-box;
  }
  
  .amino-acid-sequence:hover {
    cursor: grabbing;
  }
  
  .amino-acid {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial;
    height: 16px;
    margin-right: 0px;
    border-right: 1px solid black;
    background-color: #ddd;
    border-radius: 0px;
    user-select: none;
  }
  
  .amino-acid:hover {
    background-color: #ccc;
  }
  
  .selected {
    background-color: #666;
    color: #fff;
  }
  