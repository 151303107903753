.App {
  text-align: center;
}

.searchBar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  font-size: 18px;
}

.searchBarWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
}

.searchResultWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #ccc;
}

.searchResultList {
  list-style: none;
  margin: 0 0;  
  display: inline-block;
  text-align: left;
  min-width: 200px;
}

.searchResultItem {
  margin: 0 0;
  padding-left: 5px;
  padding:5px 5px;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  font-family: Arial, sans-serif;
  min-width: 200px;
}

.App-header {
  background-color: #FFF;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border-bottom: 1px solid #ccc;

}

.App-link {
  color: #61dafb;
}
.title{
  position: relative;
  padding-top:5px;
  padding-bottom:5px;
  //min-height: 10 vh;
  text-align: center;
  font-size: 2.5em;
  font-weight: bold;
  color: #000000;
  font-family: Arial, sans-serif;
  border-bottom: 1px solid #ccc;
}

.subtitle{
  position: relative;
  padding-top:5px;
  padding-bottom:5px;
  //min-height: 10 vh;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  color: #000000;
  font-family: Arial, sans-serif;
  border-bottom: 1px solid #ccc;
}

.link{
  position: relative;
  padding-top:5px;
  padding-bottom:5px;
  //min-height: 10 vh;
  text-align: center;
  font-size: 1em;
  color: #000000;
  font-family: Arial, sans-serif;
  border-bottom: 1px solid #ccc;
}
.link:hover {
  background-color: #f5f5f5;
}
a {
  display: block;
  text-decoration: none;
  color: #333;
}
