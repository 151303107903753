.general-properties-table {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    font-family: Arial, sans-serif;
}

.general-properties-table .tbody{
  width:100%;
}

.general-properties-table .column {
  flex: 1;
  padding: 2px;
}

.general-properties-table .column:first-child {
  max-width: 300px;
  text-align: right;
  font-size: 20px;
}

.general-properties-table .column:last-child {
  width: 70%;
  word-wrap: break-word; 
  overflow-wrap: break-word;
}

.general-properties-table .row {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
}

.tooltip {
  position: absolute;
  text-align: left;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 4px;
  pointer-events: none; /* Ensures tooltip doesn't interfere with mouse events */
  z-index: 9999;
  display: block;
}
.flip-switch {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 32px;
}

.flip-switch-checkbox {
  display: none;
}

.flip-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 34px;
  border: 2px solid #ccc;
  height: 25px;
  position:relative;
  transition: margin 0.2s ease-in 0s;
}

.flip-switch-inner {
  display: block;
  width: 100%;
  margin-left: -100%;
  transition: margin 0.2s ease-in 0s;
}

.flip-switch-switch {
  display: block;
  width: 22px;
  margin: 4px;
  background: #ccc;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 26px;
  transition: all 0.2s ease-in 0s;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
}

.flip-switch-checkbox:checked + .flip-switch-label {
    background: #ccc;
    margin-left: 0;
}

.flip-switch-checkbox:checked + .flip-switch-label .flip-switch-switch {
  right: 0px;
  background: #f55;
}

  
