.radarcontainer {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.radar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #ffffff;
    position: relative;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.radar-sweep {
    position: 'absolute';
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: conic-gradient(
        #00ff0000 0deg,
        #00ff0000 240deg, 
        #ff0000 360deg);
    //animation: rotate 2s linear infinite;
}

.s-container {
    position: absolute;
    top:-4%;
    left:50%;
    transform:translate(-50%, 0%);
  }
  
  .s {
    font-size:95px;
    font-weight: bold;
    color: #000000;
    font-family: Arial, sans-serif;
  }