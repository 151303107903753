.searchBar {
    display: flex;
  align-items: center;
  margin-top: 0;
}

.input {
    flex-grow: 1;
    height: 40px;
    padding: 0 20px;
    border: 1px solid #ccc;
    border-radius: 0 0 0 0;
    font-size: 22px;
    font-family: Arial, sans-serif;
    color: #555;
}

.search-btn {
    height: 40px;
    min-height: 40px;
    padding: 0 20px;
    background-color: #FFCCCC;
    color: #000000;
    border: 1px solid #ccc;
    border-radius: 0 0 0 0;
    font-size: 22px;
    font-family: Arial, sans-serif;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .search-btn:hover {
    background-color: #C8C8C8;
  }

.searchBarWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
}

.searchResultWrap {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #ccc;
  z-index: 99;
}

.searchResultList {
  list-style: none;
  margin: 0 0;  
  display: inline-block;
  text-align: left;
  min-width: 200px;
}

.searchResultItem {
  margin: 0 0;
  padding-left: 5px;
  padding:5px 5px;
  border-top: 1px solid #ccc;
  font-family: Arial, sans-serif;
  min-width: 200px;
}
.searchResultItem:hover {
  border: 2px solid black;
}

a {
  display: block;
  text-decoration: none;
  color: #333;
}
.hitsDescription{
  fill: white;
  background: 1px solid #ccc;
}
