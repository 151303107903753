.chart-container.ibaq {
  position: relative;  
  width: 850px; /* adjust as needed */
  margin: 0 auto;
  float: left; /* add this line to float the container to the left */
  overflow:visible;
}
.chart-container.ibaq svg {
    width: 100%;
    min-height:500px;
    position: relative;
    overflow: visible;
    border: 1px solid #ccc;
}