.chart-container.heatmap {
  position: relative;  
  width: 100%; /* adjust as needed */
  margin: 0 auto;
  float: left; /* add this line to float the container to the left */
  overflow:visible;
  
}
.savebtn_container{
  position: absolute;  
  top:0;
  left:0;
  z-index: 2;
  white-space: nowrap;
  font-size:20px;
  font-family:Arial;
}

.chart-container.heatmap svg {
  position: absolute;  
  overflow: visible;
  min-height:500px;
  background-color: white;
}
.cell-rect:hover {
    stroke-width: 2px;
  }
  .tooltip {
    position: fixed;
    top:0;
  left:0;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 12px;
    z-index: 999;
    display: none;
  }
  